import React from 'react';
import { useLocation } from 'react-router-dom';
import colors from '../../assets/colors';
import Logo from '../../assets/Logo-white.png'
import { IsMobile, IsTablet, useWindowSize } from '../../utils/UIHooks';

const Footer: React.FC = () => {
  const { width } = useWindowSize()
  const tablet = IsTablet(width)
  const mobile = IsMobile(width)

  const location = useLocation()
  if (location.pathname.includes("printable")) return <></>

  return (
    <div style={{ backgroundColor: colors.primary, color: colors.white }}>
      <div style={{
        padding: mobile ? 40 : 80, marginTop: 120, display: "grid", gridTemplateColumns: tablet ? '1fr' : '2fr 1fr 1fr', gridColumnGap: 50, gridRowGap: 50
      }}>
        <div>
          <img src={Logo} alt="Contract cloud logo" style={{ width: 140 }} />

          <p className="white">Contract cloud is a modern platform for secure signature contract handling. The digital signatures are valid in most countries and legally binding, exactly like we use a signature on paper. </p>
          <p className="white">All contract and all signatures are recorded in our secure and encrypted database, where all parties will have access to the contract, and we will also send a digital PDF to your email.</p>
        </div>
        <div>
          <h5 style={{ color: colors.white }}>About</h5>
          <ul style={{ margin: 0, padding: 0, listStyleType: 'none', color: colors.white }}>
            <li><a className="white" href='http://contract-cloud.io/' target='_blank' rel="noreferrer" >Terms and conditions</a></li>
            <li><a className="white" href='http://contract-cloud.io/' target='_blank' rel="noreferrer" >FAQ</a></li>
            <li><a className="white" href='http://contract-cloud.io/' target='_blank' rel="noreferrer" >Contact</a></li>
            <li><a className="white" href='https://contractcloudio.tawk.help/' target='_blank' rel="noreferrer" >Support center</a></li>

          </ul>
        </div>
        <div>
          <h5 style={{ color: colors.white }}>Use the product</h5>
          <ul style={{ margin: 0, padding: 0, listStyleType: 'none', color: colors.white }}>
            <li><a className="white" href='http://contract-cloud.io/' target='_blank' rel="noreferrer" >Features</a></li>
            <li><a className="white" href='http://contract-cloud.io/' target='_blank' rel="noreferrer" >Pricing</a></li>
            <li><a className="white" href='https://documenter.getpostman.com/view/4325089/UVkvJsDV' target='_blank' rel="noreferrer" >For developers</a></li>
          </ul>
        </div>
      </div>
      <div>
        <p style={{ margin: 0, marginTop: 30, textAlign: 'center' }}>Contract-Cloud.io - All rights reserved - {(new Date).getFullYear()} </p>
        <p style={{ margin: 0, paddingBottom: 30, textAlign: 'center' }}>Strandvejen 100, 2900 Hellerup, Denmark, CVR: 40431934</p>
      </div>
    </div>
  )
}

export default Footer;
