type ApiEndpoint = {
    name: string,
    endpoint: string,
    region: string
}

type AppConfigs = {
    API: {
        endpoints: ApiEndpoint[]
    }

}

const testEndPoint = "https://848fah2c75.execute-api.eu-west-1.amazonaws.com/testing"

const region = "eu-west-1";
export const UserAPI = "CC-USER-API";
export const ErrorChannel = "ErrorChannel";

const defaultconfig: AppConfigs = {
    API: {
        endpoints: [{
            name: UserAPI,
            endpoint: process.env.REACT_APP_API === "local" ? "http://localhost:3000/dev" : "https://7vsd27v924.execute-api.eu-west-1.amazonaws.com/dev",
            region,
        }]
    }
}

export default defaultconfig