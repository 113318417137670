import React from 'react';
import { HorizontalFlexContainer } from '../templates/Wrappers';
import Logo from '../../assets/Logo.png'
import colors from '../../assets/colors';
import { useLocation } from 'react-router-dom';
import { MenuButton, SupportedIcons } from '../atoms/Inputs';


const Menu: React.FC<{ contractId?: string }> = ({ contractId }) => {
  const location = useLocation()
  if (location.pathname.includes("printable")) return <></>

  return (
    <HorizontalFlexContainer style={{
      justifyContent: 'space-between', padding: 10,
      boxShadow: colors.boxShadow, backgroundColor: colors.white,
      marginBottom: 20
    }}>
      <img src={Logo} alt="Contract cloud logo" style={{ width: 83 }} />
      <HorizontalFlexContainer>
        {contractId && (
          <a href={'/print/'+contractId} target='_blank' rel="noreferrer">
            <MenuButton icon={SupportedIcons.DownloadIcon} buttonTitle="DOWNLOAD" />
          </a>
        )}
        <a href='https://contractcloudio.tawk.help/' target='_blank' rel="noreferrer">
          <MenuButton icon={SupportedIcons.SupportIcon} buttonTitle="SUPPORT" />
        </a>

      </HorizontalFlexContainer>
    </HorizontalFlexContainer>
  )
}

export default Menu;
