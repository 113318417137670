import React from 'react';
import colors from '../../assets/colors';
import { ContractTemplateAttachment } from '../../shared/Public';
import { NiceDate } from '../../utils/UIHooks';
import { Button } from '../atoms/Inputs';
import { HorizontalFlexContainer } from '../templates/Wrappers';


const AttachmentContainer: React.FC<{ attachment: ContractTemplateAttachment }> = ({ attachment }) => {

  return (
    <HorizontalFlexContainer style={{
      marginBottom: 16, justifyContent: 'space-between',
      borderBottomWidth: 0.5, borderBottomColor: colors.primary, borderBottomStyle: 'solid'
    }}>
      <div>
        <p><b>{attachment.name}</b></p>
        <p>{NiceDate(attachment.uploadDate)}</p>
      </div>
      <Button title="Open attachment" link={attachment.url} />
    </HorizontalFlexContainer>
  )
}


export default AttachmentContainer;
