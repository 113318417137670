import React from 'react';
import { Paper } from '../components/templates/Wrappers';
import DefaultPageTemplate from '../components/templates/DefaultPageTemplate';


const TermsAndConditionsPage: React.FC = () => {

  return (
    <DefaultPageTemplate>
      <Paper>
        <script id="CookieDeclaration" src="https://consent.cookiebot.com/261395a0-fab7-4244-9ba1-0389e854fa17/cd.js" type="text/javascript" async></script>
      </Paper>
    </DefaultPageTemplate>
  )
}
export default TermsAndConditionsPage;
