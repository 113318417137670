//AUTOMATIC UPDATED

export type OrganizationId = string

export type Party = {
    id: string;
    fullname: string;
    address: string;
    email: string;
    phoneNumber: string;
}

export type ContractMappings = { [key: string]: string };

export type CreateContractRequest = {
    templateId: string;
    mappings: ContractMappings;
    parties: Party[];
}

export type CreateHTMLContract = {
    contractTitle: string;
    htmlContent: string;
    organizationId: string;
    parties: Party[];
    attachmentsURL: string[];
}


export enum SignStatus {
    success = "success",
    declined = "declined",
    incomplete = "incomplete",
}

export type Signature = Party & {
    signatureStatus: SignStatus;
    isUser?: boolean
}

export type ContractTemplateAttachment = {
    name: string;
    url: string;
    uploadDate: string;
}

export type Contract = {
    id: string;
    title: string;
    html: string;
    signatures: Signature[];
    organizationId: OrganizationId;
    attachments?: ContractTemplateAttachment[];
}


export type ContractAccessRequest = {
    emailInvitation?: boolean;
    contractId: string;
    partyId: string;
}
export type ContractAccess = ContractAccessRequest & {
    id: string;
    created: string;
    ttlInMin?: number;
}

export type ContractSignatureWebhook = {
    webhookId: string;
    contract: Contract;
    signature: Signature;
    signatureDate: string;
}

export type CustomContractResponse = {
    contractId: string;
    contractUrl: string;
}

//pingTime is using UCT
export type ServerStatus = {
    serverId: string;
    pingTime: string;
}

export default Contract