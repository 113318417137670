
const primary = "#003049"
const text = "#2f2f2f"
const heading = "#141125"
const boxShadow = "0 10px 25px 0 rgb(0 0 0 / 12%)"
const success = "#17cb6e"
const error = "#ff4545"
const white = "#fff"

const gray = "#9B9B9B"
const lightGray = "#F2F2F2"


const map = { primary, text, heading, boxShadow, success, error, white, gray, lightGray } 

export default map