import React from 'react';
import SignContainer from '../components/molecules/SignContainer';
import { useParams } from 'react-router-dom';
import { getContractById } from '../utils/APIHooks';
import Logo from '../assets/loading.gif'
import Contract from '../shared/Public';


const ContractPagePrintable: React.FC<{ instantPrint?: boolean }> = ({ instantPrint }) => {
  const { id } = useParams<{ id: string }>()
  const [contract, setContract] = React.useState<Contract>()

  React.useEffect(() => {
    (async () => {
      if (!id) return
      const result = await getContractById(id)
      setContract(result)
      if (instantPrint) {
        setTimeout(() => {
          window.print()
        }, 100)
      }
    })()
  }, [id])



  if (!contract) return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 150, marginBottom: 300 }}>
      <img src={Logo} alt="Contract cloud logo" style={{ width: 140 }} />
    </div>
  )

  return (
    <div id="print">
      <h5 style={{ paddingTop: 100, paddingBottom: 30 }}>{contract.title}</h5>
      <SignContainer contract={contract} />
      <div dangerouslySetInnerHTML={{ __html: contract.html }} />
      <SignContainer signable contract={contract} />
    </div>
  )
}

export default ContractPagePrintable;
