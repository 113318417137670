import { CompleteSignatureRequest, CompleteSignatureResponse, SignatureRequest } from "../shared/Contract"
import {API} from "@aws-amplify/api";
import { UserAPI } from "./config";
import Contract from "../shared/Public";


async function wrapper<T>(execute: () => Promise<T>): Promise<T | undefined> {
    try {
        const result = await execute()
        
        return result
    } catch (error: any) {
        console.log(error)
      
        console.log(error)
    }
    return undefined;
}


export const getContractById = async (id: string): Promise<Contract | undefined> => wrapper(async () => {
    const path = '/contractaccess/' + id
    return await API.get(UserAPI, path, {}) as Contract
})

export const startSignature = async (body: SignatureRequest): Promise<boolean | undefined> => wrapper(async () => {
    const path = '/signature/start'
    return await API.post(UserAPI, path, { body }) as boolean
})

export const completeSignature = async (body: CompleteSignatureRequest): Promise<CompleteSignatureResponse> => wrapper(async () => {
    const path = '/signature/complete'
    return await API.post(UserAPI, path, { body })
})
