import React from 'react';
import { Paper, VerticalFlexCotainer } from '../components/templates/Wrappers';
import SignContainer from '../components/molecules/SignContainer';
import { useParams } from 'react-router-dom';
import { getContractById } from '../utils/APIHooks';
import Logo from '../assets/loading.gif'
import Contract from '../shared/Public';
import DefaultPageTemplate from '../components/templates/DefaultPageTemplate';
import AttachmentContainer from '../components/molecules/AttachmentContainer';
import Menu from '../components/molecules/Menu';
import Footer from '../components/molecules/Footer';

const ContractPage: React.FC = () => {
  console.log("COTRACT PAGE")
  const { id } = useParams<{ id: string }>()
  const [contract, setContract] = React.useState<Contract>()

  React.useEffect(() => {
    console.log(id);

    (async () => {
      if (!id) return
      const result = await getContractById(id)
      setContract(result)
    })()
  }, [id])



  if (!contract) return (
    <DefaultPageTemplate>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 150, marginBottom: 300 }}>
        <img src={Logo} alt="Contract cloud logo" style={{ width: 140 }} />
      </div>
    </DefaultPageTemplate>

  )
  const attachments = contract.attachments
  return (
    <DefaultPageTemplate>
      <Menu contractId={id}/>
      <Paper>
        <h5>{contract.title}</h5>
        <SignContainer contract={contract} />
      </Paper>
      <Paper>
        <div dangerouslySetInnerHTML={{ __html: contract.html }} />
      </Paper>
      {attachments && attachments.length > 0 && (
        <Paper>
          <h5>Attachments</h5>
          <VerticalFlexCotainer>
            {attachments.map((a, key) => <AttachmentContainer key={key} attachment={a} />)}
          </VerticalFlexCotainer>
        </Paper>
      )}
      <Paper>
        <SignContainer signable contract={contract} />
      </Paper>
      <Footer/>
    </DefaultPageTemplate>
  )
}

export default ContractPage;
