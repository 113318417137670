import React from 'react';
import { Paper } from '../components/templates/Wrappers';
import DefaultPageTemplate from '../components/templates/DefaultPageTemplate';


const ErrorPage: React.FC = () => {

  return (
    <DefaultPageTemplate>
      <Paper>
        <h4>Error</h4>
        <code>We couldn't find the page you are looking for</code>
      </Paper>
    </DefaultPageTemplate>
  )
}
export default ErrorPage;
