

export const SupportIcon: React.FC<{ color?: string }> = ({ color }) => {

    const fill = color || "#9B9B9B"
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5 7.5C11.65 7.5 7.64995 8.1375 7.51245 12.35C9.66245 11.85 11.3375 10.1375 11.7625 7.9625C12.425 9.4 14.2125 11.4625 17.3 11.2125C16.7375 9.075 14.8125 7.5 12.5 7.5V7.5Z" fill={fill} />
            <path d="M9.375 14.375C9.89277 14.375 10.3125 13.9553 10.3125 13.4375C10.3125 12.9197 9.89277 12.5 9.375 12.5C8.85723 12.5 8.4375 12.9197 8.4375 13.4375C8.4375 13.9553 8.85723 14.375 9.375 14.375Z" fill={fill} />
            <path d="M15.625 14.375C16.1428 14.375 16.5625 13.9553 16.5625 13.4375C16.5625 12.9197 16.1428 12.5 15.625 12.5C15.1072 12.5 14.6875 12.9197 14.6875 13.4375C14.6875 13.9553 15.1072 14.375 15.625 14.375Z" fill={fill} />
            <path d="M20 12.5C20 8.3625 16.6375 5 12.5 5C8.3625 5 5 8.3625 5 12.5C4.3125 12.5 3.75 13.0625 3.75 13.75V16.25C3.75 16.9375 4.3125 17.5 5 17.5H6.25V12.5C6.25 9.05 9.05 6.25 12.5 6.25C15.95 6.25 18.75 9.05 18.75 12.5V18.75H11.25V20H18.75C19.4375 20 20 19.4375 20 18.75V17.5C20.6875 17.5 21.25 16.9375 21.25 16.25V13.75C21.25 13.0625 20.6875 12.5 20 12.5Z" fill={fill} />
        </svg>
    )
}

export const DownloadIcon: React.FC<{ color?: string }> = ({ color }) => {

    const fill = color || "#9B9B9B"
    return (
        <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.4523 9.375H15.7797V4.16667C15.7797 3.59375 15.3063 3.125 14.7277 3.125H10.5198C9.94119 3.125 9.4678 3.59375 9.4678 4.16667V9.375H7.79516C6.85889 9.375 6.3855 10.5 7.04825 11.1562L11.8768 15.9375C12.2871 16.3437 12.9499 16.3437 13.3601 15.9375L18.1887 11.1562C18.8515 10.5 18.3886 9.375 17.4523 9.375ZM5.25989 19.7917C5.25989 20.3646 5.73328 20.8333 6.31187 20.8333H18.9356C19.5142 20.8333 19.9876 20.3646 19.9876 19.7917C19.9876 19.2187 19.5142 18.75 18.9356 18.75H6.31187C5.73328 18.75 5.25989 19.2187 5.25989 19.7917Z" fill={fill} />
        </svg>

    )
}


