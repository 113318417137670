import React from 'react';
import styled from 'styled-components';
import colors from '../../assets/colors';
import { Button } from '../atoms/Inputs';
import { HorizontalFlexContainer, VerticalFlexCotainer } from '../templates/Wrappers';

import CheckIcon from '../../assets/icons/check.png'

import DeclineIcon from '../../assets/icons/pending.png'
import PhoneInput from 'react-phone-number-input'
import { E164Number } from 'libphonenumber-js/min';
import 'react-phone-number-input/style.css'
import { completeSignature, startSignature } from '../../utils/APIHooks';
import { HorizontalMobileMax, IsHorizontalMobile, IsTablet, useWindowSize } from '../../utils/UIHooks';
import Contract, { Signature, SignStatus } from '../../shared/Public';
import { GeoLocation } from '../../shared/Contract';
import { ErrorList } from '../organisms/ErrorHandler';

const indexToName = (index: number): string => {
  if (index === 0) return "one"
  if (index === 1) return "two"
  if (index === 2) return "three"
  if (index === 3) return "four"
  if (index === 4) return "five"
  else return (index + 1).toString()
}


const InputIndicator = styled.span`
  margin: 10px 0px 0px 0px;
  padding: 0px 8px;
`;

const CreateSignatureBox: React.FC<{ contractId: string, signature: Signature, updateStatus: (status: SignStatus) => void, showError(msg: string):void }> =
  ({ contractId, signature, updateStatus, showError }) => {
    const [signProcessStarted, setSignProcessStarted] = React.useState(false)
    const [phoneNumber, setPhoneNumber] = React.useState<E164Number>(signature.phoneNumber)
    const [email, setEmail] = React.useState<string>(signature.email)
    const [checked, setChecked] = React.useState(false)

    const [smsCode, setSmsCode] = React.useState<string>('')
    const [geoLocation, setGeoLocation] = React.useState<GeoLocation>()
    const [geoLocationEnabled, setGeoLocationEnabled] = React.useState(false)
    const [loadingGeoLocation, setLoadingGeoLocation] = React.useState(false)
    const [loadingSMS, setLoadingSMS] = React.useState(false)
    const [showSMSCodeInput, setShowSMSCodeInput] = React.useState(false)
    const [loadingSignature, setLoadingSignature] = React.useState(false)


    const enableLocation = () => {
      if (geoLocationEnabled) {
        return
      }
      setLoadingGeoLocation(true)
      navigator.geolocation.getCurrentPosition((res) => {
        setGeoLocation({ latitude: res.coords.latitude, longitude: res.coords.longitude })
        setGeoLocationEnabled(true)
        setLoadingGeoLocation(false)
      }, () => setLoadingGeoLocation(false))
    }

    const submitInfo = async () => {
      setLoadingSMS(true)
      await startSignature({ contractId, partyId: signature.id, phoneNumber: phoneNumber.toString(), location: geoLocation })
      setShowSMSCodeInput(true)
      setLoadingSMS(false)
    }
    const submitSignature = async () => {
      if (!smsCode) return showError("No code provided")
      if (smsCode.length < 6) return showError("Code is to short")
      if (!checked) return showError("Please accept the terms and conditions")
      setLoadingSignature(true)
      const result = await completeSignature({ contractId, partyId: signature.id, code: smsCode })
      if (result.sucess === true) updateStatus(SignStatus.success)
      else {
        setShowSMSCodeInput(false)
        updateStatus(SignStatus.incomplete)
        showError("Please try again")
        showError(result.message)
      }
      setLoadingSignature(false)
      setSmsCode("")
    }

    const hanldePhoneNumberChange = (value?: E164Number) => {
      if (!value) return;
      setPhoneNumber(value)
    }

    if (!signProcessStarted) return (
      <Button id="signaturebox" title='Sign now' onClick={() => setSignProcessStarted(true)} />
    )

    if (showSMSCodeInput) return (
      <VerticalFlexCotainer style={{ width: '100%' }}>
        <InputIndicator>Signature code</InputIndicator>
        <input placeholder='6-digit code' value={smsCode} onChange={(e) => setSmsCode(e.target.value)} />
        <HorizontalFlexContainer style={{ marginTop: 12 }}>
          <input type='checkbox' style={{ boxShadow: 'none' }} checked={checked} onChange={() => setChecked(!checked)} />
          <InputIndicator style={{ margin: 0 }}>I herby declare that i am <b>{signature.fullname}</b> and understand that the contract is legal obliga</InputIndicator>
        </HorizontalFlexContainer>
        <Button loading={loadingSignature} onClick={submitSignature} style={{ display: 'flex', alignItems: 'center' }}>Sign contract</Button>
      </VerticalFlexCotainer>
    )

    return (
      <VerticalFlexCotainer style={{ width: '100%' }}>
        <InputIndicator>E-Mail</InputIndicator>
        <input value={email} onChange={(e) => setEmail(e.target.value)} />
        <InputIndicator>Mobile phone</InputIndicator>
        <PhoneInput placeholder="Mobile phone" value={phoneNumber} onChange={hanldePhoneNumberChange} />
        <InputIndicator>Location</InputIndicator>
        <Button loading={loadingGeoLocation} onClick={enableLocation} secondary
          title={geoLocationEnabled ? 'Your locaiton: lat' + geoLocation?.latitude + ' long' + geoLocation?.longitude : 'Enable location'} />
        <Button loading={loadingSMS} onClick={submitInfo} style={{ display: 'flex', alignItems: 'center' }}>
          Get sms code
        </Button>
      </VerticalFlexCotainer>
    )

  }

const SignBoxWrapper = styled.div<{ status?: SignStatus }>`
  color: ${colors.heading};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
`;

const SignBox: React.FC<{ contractId: string, signable?: boolean, signature: Signature, children?: React.ReactNode | React.ReactNode[], showError(msg: string):void }> = ({ showError, contractId, signable, signature, children }) => {
  const [localStatus, setLocalStatus] = React.useState(signature.signatureStatus)
  const success = localStatus === SignStatus.success

  return (
    <SignBoxWrapper status={localStatus}>
      <div style={{ padding: 10 }}>
        {children}

      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between', padding: 10,
        borderBottomRightRadius: 10, borderBottomLeftRadius: 10
      }}>
        {signature.isUser && !success && signable && <CreateSignatureBox showError={showError} contractId={contractId} updateStatus={setLocalStatus} signature={signature} />}
        {signature.isUser && !success && !signable && <Button title="Start signature process" onClick={() => window.location.href = '#signaturebox'} />}

        {!signature.isUser && !success && (
          <HorizontalFlexContainer>
            <img src={DeclineIcon} alt="Contract cloud signture completed" style={{ height: 30, marginRight: 15 }} />
            <p style={{ margin: 0 }}>Signature pending</p>
          </HorizontalFlexContainer>
        )}
        {success && (
          <HorizontalFlexContainer >
            <img src={CheckIcon} alt="Contract cloud signture declined" style={{ height: 30, marginRight: 15 }} />
            <p style={{ margin: 0 }}>Signature completed</p>
          </HorizontalFlexContainer>
        )}
      </div>
    </SignBoxWrapper>
  )
}

const SignContainer: React.FC<{ signable?: boolean, contract: Contract }> = ({ signable, contract }) => {
  const { width } = useWindowSize()
  const horizontalMobile = IsHorizontalMobile(width)
  const tablet = IsTablet(width)
  const [errorList, setErrorList] = React.useState<string[]>([])

  const addError = (msg:string)=> setErrorList([...errorList, msg])

  return (
    <div style={{
      display: 'grid', gridTemplateColumns: tablet ? '1fr' : 'repeat(2, 1fr)', columnGap: 20,
      rowGap: 20, width: horizontalMobile ? 'auto' : HorizontalMobileMax
    }}>
      <ErrorList errorList={errorList} setErrorList={setErrorList} />
      {contract.signatures.map((item, index) => (
        <SignBox signature={item} signable={signable} key={index} contractId={contract.id} showError={addError}>
          <h6>Part {indexToName(index)}</h6>
          <p className='small'>{item.fullname}</p>
          <p className='small'>{item.address}</p>
          <p className='small'>{item.email}</p>
        </SignBox>
      ))}
    </div>
  )
}

export default SignContainer;
