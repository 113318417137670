import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';
import ContractPagePrintable from './pages/ContractPagePrintable';
import ContractPage from './pages/ContractPage';
import ErrorPage from './pages/ErrorPage';
import { Amplify } from 'aws-amplify';
import config from './utils/config';
import './index.scss';
import FrontPage from './pages/FrontPage';

Amplify.configure(config);

const router = createBrowserRouter([
  {
    path: "/",
    element: <FrontPage />
  },
  {
    path: "terms",
    element: <TermsAndConditionsPage />
  },
  {
    path: "print/:id",
    element: <ContractPagePrintable instantPrint={true} />
  }, 
  {
    path: "printable/:id",
    element: <ContractPagePrintable />
  },
  {
    path: "contract/:id",
    element: <ContractPage />
  }, 
  {
    path: "*",
    element: <ErrorPage />
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <RouterProvider router={router} />

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
