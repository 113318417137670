import React from 'react';
import styled from 'styled-components';
import colors from '../../assets/colors';
import { HorizontalMobileMax, IsMobile, useWindowSize } from '../../utils/UIHooks';

export const HorizontalFlexContainer: React.FC<{ style?: React.CSSProperties, children?: React.ReactNode | React.ReactNode[] }> = ({ children, style }) => {
    const defaultStyles: React.CSSProperties = { display: 'flex', flexDirection: 'row', alignItems: 'center' }
    return <div style={style ? { ...defaultStyles, ...style } : defaultStyles}>{children}</div>
}

export const VerticalFlexCotainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const PaperRoot = styled.div`
    box-shadow: 0 10px 25px 0 rgb(0 0 0 / 12%);
    border-radius: 2px;
    margin: 20px;
    flex: auto;
    background-color: ${colors.white};
`;

export const Paper: React.FC<{ children?: React.ReactNode | React.ReactNode[] }> = ({ children }) => {
    const { width } = useWindowSize()
    const mobile = IsMobile(width)

    return <div style={{ display: mobile ? 'inline' : 'flex', justifyContent: 'center' }}>
        <PaperRoot style={{ padding: mobile ? 22 : 44, maxWidth: HorizontalMobileMax }}>{children}</PaperRoot>
    </div>
}
