import React from 'react';
import colors from '../../assets/colors';
import CloseIcon from '../../assets/icons/close-icon-white.png'
import { HorizontalFlexContainer } from '../templates/Wrappers';

const ErrorBox: React.FC<{ onClose:()=>void, errorMessage: string }> = ({ onClose, errorMessage }) => {
    return (
        <HorizontalFlexContainer style={{ backgroundColor: colors.error, padding: 10, margin: 10, borderRadius: 2, color: colors.white, justifyContent:'space-between', alignItems:'center' }}>
            <span><b>Error: </b>{errorMessage}</span>
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', cursor:'pointer' }} onClick={onClose}>
                <img style={{width: 20, height: 20}} alt='Close icon' src={CloseIcon}/>
            </div>
        </HorizontalFlexContainer>
    )
}

export const ErrorList: React.FC<{ setErrorList: (updatedErrorList: string[]) =>void,  errorList: string[] }> = ({setErrorList, errorList }) => {

    const closeHandler = (index: number) => {
        const newList = errorList.filter((_, i)=> i!==index)
        setErrorList(newList)
    }

    return (
        <div style={{
            display: 'flex', width: 300, maxWidth: '90%', flexDirection: 'column',
            position: 'fixed', bottom: 50, right: 30
        }}>
            {errorList && errorList.map((item, key) => <ErrorBox onClose={()=>closeHandler(key)} errorMessage={item} key={key} />)}
        </div>
    )
}