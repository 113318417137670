import React from 'react';
import { Paper } from '../components/templates/Wrappers';
import DefaultPageTemplate from '../components/templates/DefaultPageTemplate';
import Menu from '../components/molecules/Menu';
import Footer from '../components/molecules/Footer';


const FrontPage: React.FC = () => {

  return (
    <DefaultPageTemplate>
      <Menu />
      <Paper>
        <h3>Welcome to contract-cloud.io</h3>
        <p>We are developing a marketings-site for the Contract Cloud product</p>
        <p>Please contact us by email for any inquiries: <a href='mailto:web@contract-cloud.io'>web@contract-cloud.io</a></p>
      </Paper>
      <div style={{height:window.innerHeight/3, width: '100%'}}/>
      <Footer/>
    </DefaultPageTemplate>
  )
}
export default FrontPage;
