import React from 'react';
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import colors from '../../assets/colors';
import { DownloadIcon, SupportIcon } from './Icons';

type ButtonProps = { title?: string, link?: string, onClick?: () => void, style?: React.CSSProperties, loading?: boolean, secondary?: boolean, id?: string, children?: React.ReactNode | React.ReactNode[] }
export const Button: React.FC<ButtonProps> = ({ title, link, onClick, style, loading, children, secondary, id }) => {
  const isHrefLink = !!link
  //Todo replace with actual link
  return <a id={id || ''} rel="noreferrer" href={isHrefLink ? link : undefined} onClick={onClick} target={isHrefLink ? '_blank' : '_self'} className={secondary ? "button secondary" : "button"} style={style || {}}>{loading ? <Dots style={{ marginRight: 10 }} /> : (title || children)}</a>
}

export enum SupportedIcons { SupportIcon, DownloadIcon }
export const MenuButton: React.FC<{ icon: SupportedIcons, buttonTitle: string }> = ({ buttonTitle, icon }) => {
  const [open, setOpen] = React.useState<boolean>(false)

  const suportIcon = icon === SupportedIcons.SupportIcon
  const downloadIcon = icon === SupportedIcons.DownloadIcon

  const color = open ? colors.text : colors.gray

  return (
    <div onMouseEnter={() => setOpen(true)} onMouseOver={() => setOpen(true)} onMouseLeave={() => setOpen(false)}
      style={{
        display: 'flex', alignItems: 'center',
        width: open ? 130 : 28, height: 28, background: colors.lightGray, borderRadius: 2, paddingLeft: 4,
        transition: "all 0.3s ease", marginLeft: 10,
      }}>
      {suportIcon && <SupportIcon color={color} />}
      {downloadIcon && <DownloadIcon color={color} />}

      {open && <span style={{
        fontSize: 12, paddingLeft: 4, lineHeight: "25px", transition: "all 0.5s ease",
        fontWeight: 600, color, display: open ? 'inline' : 'none'
      }}>
        {buttonTitle}
      </span>}
    </div>
  )
}
